@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

@font-face {
  font-family: "Rothek";
  src: local("RothekVariable"),
    url("./fonts/RothekVariable.ttf") format("truetype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #fff6f4;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
    color: white;
  }
}

.font-rh {
  font-weight: 120;
}

/* homePage illustration animation */

@keyframes enterRight {
  0% {
    transform: translateX(100%);
  }
  30% {
    transform: translateX(100%);
  }
  33% {
    transform: translateX(0%);
  }
  70% {
    transform: translateX(0%);
  }
  73% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes scale {
  0% {
    transform: scale(10%) translate(400%, 200%);
    opacity: 0;
  }
  30% {
    transform: scale(10%) translate(400%, 200%);
    opacity: 0;
  }
  38% {
    transform: scale(100%) translate(0px, 0px);
    opacity: 1;
  }
  65% {
    transform: scale(100%) translate(0px, 0px);
    opacity: 1;
  }
  70% {
    transform: scale(10%) translate(400%, 200%);
    opacity: 0;
  }
  100% {
    transform: scale(10%) translate(400%, 200%);
    opacity: 0;
  }
}

@keyframes fade1 {
  0% {
    opacity: 0;
  }
  38% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  51% {
    opacity: 1;
  }
  53% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade2 {
  0% {
    opacity: 0;
  }
  53% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.menuLine-2 {
  transition-delay: 100ms !important;
}

.menuLine-3 {
  transition-delay: 200ms !important;
}

.menuLine-4 {
  transition-delay: 300ms !important;
}

.menuLine-5 {
  transition-delay: 400ms !important;
}

.menuLine-6 {
  transition-delay: 500ms !important;
}

.homePage_animation_character {
  animation-name: enterRight;
  animation-duration: 18s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

.homePage_animation_balloon {
  animation-name: scale;
  animation-duration: 18s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

.homePage_animation_text1 {
  animation-name: fade1;
  animation-duration: 18s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

.homePage_animation_text2 {
  animation-name: fade2;
  animation-duration: 18s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

/* mult select css */

.react-select-container {
  display: none;
  font-family: "Tillium Web", sans-serif;
  text-align: left;
  color: #333333;
  border: 2px rgb(150, 150, 150) !important;
  box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.1), 0 10px 8px rgba(0, 0, 0, 0.04);
}

@media (min-width: 1024px) {
  .react-select-container {
    display: block;
  }
}

.react-select__control--is-focused {
  border-color: rgb(150, 150, 150) !important;
  box-shadow: none !important;
}

.react-select__placeholder {
  content: "Selecione" !important;
}

.react-select__clear-indicator {
  color: #33333391 !important;
}

.react-select__clear-indicator:hover {
  color: #333333e1 !important;
  cursor: pointer;
}

.react-select__dropdown-indicator {
  color: #33333391 !important;
}

.react-select__dropdown-indicator:hover {
  color: #333333e1 !important;
  cursor: pointer;
}

.react-select__value-container:focus-visible {
  border-color: none !important;
}

.react-select__multi-value {
  background-color: rgba(166, 55, 226, 0.1) !important;
  color: rgb(166, 55, 226) !important;
}

.react-select__multi-value__label {
  color: rgb(166, 55, 226) !important;
}

.react-select__multi-value__remove:hover {
  background-color: rgb(166, 55, 226, 0.4) !important;
  color: rgb(166, 55, 226) !important;
}

.react-select__menu-list__label {
  background-color: rgb(255, 99, 14, 0.4) !important;
}

.react-select__option:hover {
  background-color: rgba(166, 55, 226, 0.25) !important;
}

.react-select__option--is-focused {
  background-color: rgba(166, 55, 226, 0.25) !important;
}

@keyframes out {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.words {
  animation: in linear 10s infinite;
}

@keyframes in {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
